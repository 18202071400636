import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "antd/dist/reset.css";
// import "antd-mobile/dist/antd-mobile.css"; // Import Antd Mobile UI styles
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap styles
import "./App.css"; // You can create this CSS file for your own styles
import MainRouter from "./router";

function App() {
  return (
    // <Router>
    //   <Switch>
    //     {/* Define your routes here */}
    //     <Route path="/" exact>
    //       {/* Home page */}
    //     </Route>
    //     <Route path="/courses/:courseId">{/* Course details page */}</Route>
    //     <Route path="/login">{/* Login page */}</Route>
    //     <Route path="/register">{/* Registration page */}</Route>
    //   </Switch>
    // </Router>
    // <div className="app-height">
    <MainRouter />
    // </div>
  );
}

export default App;
